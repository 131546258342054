import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

import logoSrc from '../../assets/images/logo.svg'

const Footer = () => {
  const { t } = useTranslation('footer')
  const { t: generalT } = useTranslation('general')

  // Rendering

  return (
    <footer>
      <a href={generalT('proLink')}><img src={logoSrc} alt="Moving Waldo pro" /></a>
      <div className={styles.rightContent}>
        <h3>{ t('title') }</h3>
        <a href={t('getStartedGuideLink')}>{ t('getStartedGuide') }</a>
        <a href={t('invitationKitLink')}>{ t('invitationKit') }</a>
        <a href={t('faqLink')}>{ t('faq') }</a>
        <div className={styles.legalContent}>
          <a href={t('termsLink')}>{ t('terms') }</a>
          <a href={t('privacyPolicyLink')}>{ t('privacyPolicy') }</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
