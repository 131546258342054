import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import {
  Button,
  Field,
  Footer,
  ImageUpload,
  LanguagePicker,
  Popover,
} from '../../components'
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from '../../helpers/regex'
import GetLinkForm from './get-link-form'
import ImportModal from './import-modal'
import useWindowSize from '../../hooks/use-window-size'
import ProAccount from '../../models/pro-account'
import { uploadImage, createProAccounts } from '../../helpers/api'

import newStartSrc from '../../assets/images/new-start.svg'
import logoSrc from '../../assets/images/logo.svg'
import redRightChevronSrc from '../../assets/images/red-right-chevron.svg'
import globeIconSrc from '../../assets/images/globe-icon.svg'
import twitterIconSrc from '../../assets/images/twitter-icon.svg'
import instagramIconSrc from '../../assets/images/instagram-icon.svg'
import facebookIconSrc from '../../assets/images/facebook-icon.svg'
import youtubeIconSrc from '../../assets/images/youtube-icon.svg'

import styles from './styles.module.scss'

const Signup = () => {
  const { t, i18n } = useTranslation('signup')
  const { t: generalT } = useTranslation('general')
  const { language } = i18n
  const { width } = useWindowSize()

  // Extract default states from query params

  const { search } = useLocation()
  const urlParams = new URLSearchParams(search)

  const extractFromUrl = (key: string) => urlParams.get(key) ?? ''

  // States

  const [firstName, setFirstName] = useState<string>(
    extractFromUrl('firstName'),
  )
  const [lastName, setLastName] = useState<string>(extractFromUrl('lastName'))
  const [phoneNumber, setPhoneNumber] = useState<string>(
    extractFromUrl('phoneNumber'),
  )
  const [email, setEmail] = useState<string>(extractFromUrl('email'))
  const [agencyName, setAgencyName] = useState<string>(
    extractFromUrl('agencyName'),
  )
  const [customMessage, setCustomMessage] = useState<string>(
    extractFromUrl('customMessage'),
  )
  const [logoFile, setLogoFile] = useState<File | undefined>()
  const [bannerFile, setBannerFile] = useState<File | undefined>()
  const [websiteUrl, setWebsiteUrl] = useState<string>(
    extractFromUrl('websiteUrl'),
  )
  const [twitterHandle, setTwitterHandle] = useState<string>(
    extractFromUrl('twitterHandle'),
  )
  const [instagramHandle, setInstagramHandle] = useState<string>(
    extractFromUrl('instagramHandle'),
  )
  const [facebookUrl, setFacebookUrl] = useState<string>(
    extractFromUrl('facebookUrl'),
  )
  const [youtubeUrl, setYoutubeUrl] = useState<string>(
    extractFromUrl('youtubeUrl'),
  )

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [showGetLinkForm, setShowGetLinkForm] = useState<boolean>(false)
  const [showImportModal, setShowImportModal] = useState<boolean>(false)

  // Errors

  const firstNameError =
    firstName.length === 0 ? generalT('requiredField') : undefined
  const lastNameError =
    lastName.length === 0 ? generalT('requiredField') : undefined
  const phoneNumberError =
    phoneNumber.length > 0 && !PHONE_NUMBER_REGEX.test(phoneNumber)
      ? generalT('invalidPhoneNumber')
      : undefined
  const emailError = !EMAIL_REGEX.test(email)
    ? generalT('invalidEmail')
    : undefined

  const formHasError =
    firstNameError ?? lastNameError ?? phoneNumberError ?? emailError

  // Handlers

  const resetForm = () => {
    setFirstName('')
    setLastName('')
    setPhoneNumber('')
    setEmail('')
    setAgencyName('')
    setCustomMessage('')
    setLogoFile(undefined)
    setBannerFile(undefined)
    setWebsiteUrl('')
    setTwitterHandle('')
    setInstagramHandle('')
    setFacebookUrl('')
    setYoutubeUrl('')

    setIsSubmitted(false)
    setIsLoading(false)
  }

  const onSubmit = async () => {
    setIsSubmitted(true)
    if (formHasError) {
      toast.error(generalT('formHasError'))
      return
    }

    try {
      setIsLoading(true)

      const proUserPictureUrl = logoFile
        ? await uploadImage(logoFile, 'logo')
        : undefined
      const proUserLogoUrl = bannerFile
        ? await uploadImage(bannerFile, 'banner')
        : undefined

      const account: ProAccount = {
        firstName,
        lastName,
        email,
        phoneNumber,
        agencyName,
        message: customMessage,
        proUserPictureUrl,
        proUserLogoUrl,
        websiteUrl,
        twitterUrl: twitterHandle,
        instagramUrl: instagramHandle,
        facebookUrl,
        youtubeUrl,
        preferredLang: language.includes('fr') ? 'fr' : 'en',
      }

      await createProAccounts([account])
      toast.success(t('thanks'))
      resetForm()
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message
      if (errorMessage === 'This account already exists') {
        toast.error(generalT('serverErrorBrokerExists'), { autoClose: false })
        return
      }
      toast.error(generalT('serverError'))
    } finally {
      setIsLoading(false)
    }
  }

  // Rendering

  return (
    <div className="container">
      <ImportModal
        show={showImportModal}
        onVisibilityChange={setShowImportModal}
      />

      <div className={styles.left}>
        <img src={newStartSrc} alt="create your pro account" />
      </div>
      <header className={styles.header}>
        <a href={generalT('proLink')}>
          <img src={logoSrc} alt="Moving Waldo pro" />
        </a>
        <div>
          <Button
            onClick={() => setShowImportModal(true)}
            className={styles.bulkCreateButton}
            reversed
          >
            { t('bulkCreate') }
          </Button>
          <LanguagePicker />
        </div>
      </header>
      <div className={styles.right}>
        <h1>
          { t('title1') }
          <br />
          { t('title2') }
        </h1>
        <div className={styles.linkHolder}>
          <h3>{ t('alreadyHaveAccount') }</h3>
          <div>
            <Popover
              width={width && width > 500 ? 380 : 250}
              align={width && width > 768 ? 'right' : 'left'}
              content={<GetLinkForm email={extractFromUrl('email')} />}
              show={showGetLinkForm}
              onShowChange={setShowGetLinkForm}
              hideOnClickOut
            >
              <button
                className={styles.linkButton}
                onClick={() => setShowGetLinkForm(true)}
              >
                { t('getMyLink') }
                <img src={redRightChevronSrc} alt="" />
              </button>
            </Popover>
          </div>
        </div>

        <br />
        <h3>{ t('yourInfo') }</h3>
        <div className="dualCols">
          <div>
            <Field
              value={firstName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFirstName(e.target.value)}
              placeholder={t('firstName')}
              infoText={t('nameInfoText')}
              error={isSubmitted ? firstNameError : undefined}
            />
            <Field
              value={lastName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setLastName(e.target.value)}
              placeholder={t('lastName')}
              infoText={t('nameInfoText')}
              error={isSubmitted ? lastNameError : undefined}
            />
            <Field
              value={phoneNumber}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPhoneNumber(e.target.value)}
              placeholder={t('phoneNumber')}
              infoText={t('phoneNumberInfoText')}
              error={isSubmitted ? phoneNumberError : undefined}
            />
          </div>
          <div>
            <Field
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)}
              placeholder={t('email')}
              infoText={t('emailInfoText')}
              error={isSubmitted ? emailError : undefined}
            />
            <Field
              value={agencyName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setAgencyName(e.target.value)}
              placeholder={t('agencyName')}
              infoText={t('agencyNameInfoText')}
            />
          </div>
        </div>

        <br />
        <h3>{ t('yourBrand') }</h3>
        <Field
          textarea
          value={customMessage}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setCustomMessage(e.target.value)}
          placeholder={t('customMessagePlaceholder')}
          infoText={t('customMessageInfoText')}
          maxLength={200}
        />

        <div className="dualCols">
          <div>
            <ImageUpload
              onImageReady={setLogoFile}
              finalSize={{ width: 112, height: 112 }}
              cropShape="round"
              buttonText={t('uploadHeadshot')}
              instructions={t('uploadHeadshotInstructions')}
              file={logoFile}
            />
          </div>
          <div>
            <ImageUpload
              onImageReady={setBannerFile}
              finalSize={{ width: 450, height: 150 }}
              buttonText={t('uploadLogo')}
              instructions={t('uploadLogoInstructions')}
              file={bannerFile}
            />
          </div>
        </div>

        <br />
        <h3>{ t('yourSocial') }</h3>
        <div className="dualCols">
          <div>
            <Field
              value={websiteUrl}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setWebsiteUrl(e.target.value)}
              placeholder={t('websiteUrl')}
              icon={globeIconSrc}
            />
            <Field
              value={twitterHandle}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setTwitterHandle(e.target.value)}
              placeholder={t('twitterHandle')}
              icon={twitterIconSrc}
            />
            <Field
              value={instagramHandle}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setInstagramHandle(e.target.value)}
              placeholder={t('instagramHandle')}
              icon={instagramIconSrc}
            />
          </div>
          <div>
            <Field
              value={facebookUrl}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFacebookUrl(e.target.value)}
              placeholder={t('facebookUrl')}
              icon={facebookIconSrc}
            />
            <Field
              value={youtubeUrl}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setYoutubeUrl(e.target.value)}
              placeholder={t('youtubeUrl')}
              icon={youtubeIconSrc}
            />
          </div>
        </div>

        <br />
        <Button
          disabled={isSubmitted && formHasError !== undefined}
          onClick={onSubmit}
          loading={isLoading}
        >
          { t('claim') }
        </Button>
        { isSubmitted && formHasError && (
          <label className={styles.hasError}>{ generalT('formHasError') }</label>
        ) }
        <Footer />
      </div>
    </div>
  )
}

export default Signup
